import './App.css';
import { InlineWidget } from "react-calendly";

function App() {
  return (
    <div className="App">
      <InlineWidget styles={{
        height: '100vh'
      }} url="https://calendly.com/winterview-dev" />
    </div>
  );
}

export default App;
